@import './firebaseui.css';

.assessment-title {
    font-family: Nunito Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

.question-header {
    font-family: Nunito Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 149%; /* 29.8px */
}

.answer-label-text {
    font-family: Nunito Sans;
    font-size: 16.8px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 15.36px */
}

.flex-1 {
    flex: 1;
    min-width: 220px;    
}

.flex-1-card {
    flex: 1;
    min-width: 350px;   
    width: 50%; 
}

.flex-1-100 {
    flex: 1;
    min-width: 150px;    
}

.btn-small {
    padding-top: 5px;
    padding-bottom: 3px;
}

.container2 {
    display: flex;
    flex-wrap: wrap;
}

.box-left {
  flex: 1;
  box-sizing: border-box;  
  margin-right: 0.5em;
}

.box-right {
  flex: 1;
  box-sizing: border-box;  
  margin-left: 0.5em;
}
@media (max-width: 767px) {
  .box-left {
    flex-basis: 100%;
    margin-right: 0px;
  }
  .box-right {
    flex-basis: 100%;
    margin-left: 0px; 
    margin-top: 1em;
  }
}

.cursor-regular {
  cursor: default;
}